//////////////////////////
///   Modules import   ///
//////////////////////////
import logo from './resources/AWS_logo_RGB_WHT.png'
import './App.css'

import React, { useState, useEffect } from 'react'
import ReactStars from 'react-stars'


// Import CloudScape components
import { 
  Container,
  ContentLayout,
  Button,
  Header,
  SpaceBetween,
  TopNavigation,
  Alert,
  Textarea,
  ExpandableSection,
  Wizard,
  ColumnLayout,
  AppLayout,
  Popover,
  StatusIndicator,
  Form,
  TextContent,
  ProgressBar,
  FormField,
  Spinner,
  Toggle
} from '@cloudscape-design/components'

// Import Amplify components
import '@aws-amplify/ui-react/styles.css'
import {Amplify, Auth, Hub, API} from 'aws-amplify' // V5_aws-amplify
//import { Amplify } from 'aws-amplify' // V6_aws-amplify
//import { Hub } from 'aws-amplify/utils' // V6_aws-amplify
//import { post } from 'aws-amplify/api' // V6_aws-amplify
//import { fetchAuthSession, signInWithRedirect } from 'aws-amplify/auth' // V6_aws-amplify


// Configure Amplify component from env.local for Cognito
Amplify.configure({
  Auth: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_12Rggu0R1",
    userPoolWebClientId: "7thb3pnt6os3m9mla5gaec8f7u",
    identityPoolId: "eu-west-1:37cb733f-4ab0-4201-9673-f31c538172fc",
    oauth: {
      domain: "oppaid-midway-integration-dev.auth.eu-west-1.amazoncognito.com",
      scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: "code"
    }
  },
  API: {
    endpoints: [
      {
        name: "api284d7fa0",
        endpoint:
        "https://hn2lzcibod.execute-api.eu-west-1.amazonaws.com/dev",
        region: "eu-west-1"
      },
    ],
  }
})

// Setup global vars
const setDebug = false
const federatedIdName = "amazonfederate";


////////////////////
///   Main App   ///
////////////////////
const App = () => {
  // Main variables set
  const TOOLNAME = "OppAId"
  const i18nStrings = {
    overflowMenuTriggerText: "More",
    overflowMenuTitleText: "All",
    stepNumberLabel: stepNumber =>
    `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) =>
    `Step ${stepNumber} of ${stepsCount}`,
    navigationAriaLabel: "Steps",
    previousButton: "Previous",
    nextButton: "Next",
    cancelButton: "Reset",
    submitButton: "Reset"
  }
  const max_len=140000 // Set maximum llm context char length for claude-v3-200k = 140k chars.
  const textRows = 25
  const sampleNotes = `
    Company Background and IT Set Up 

    * meeting participants: CSR Julie Franks, DGR Benjamin Scholz and customer Mark Grabe, IT manager of Blackbird GmbH
    * Based in Basel, Germany - 2 other locations: Berlin and Frankfurt
    * Blackbird is a retail company that sells all-black unisex clothing 
    * sell internationally via their ecommerce platform
    * 500 employees, 4 in tech aside from Mark

    Projects and Initiatives

    * looking to optimise their ecommerce website by personalising the customer journey
    * aws personalise for better suggestions based on customer interactions on the page
    * looking to optimise their website by the end of this year (Q4, 2023)
    * looking at alternative solutions from Azure
    * around 20,000 euros for the optimisation globally
    * planning to expand outside of Europe to the USA
    * interested in webhosting internationally to the USA but are currently on prem and organise their webhosting from their own premises and servers
    * considering cloud web hosting to quickly set up their infrastructure for the USA and have the website running 
    * expansion for middle of 2024
    * no budget idea yet, will be established end of this year.
    * are not familiar with the cloud so they need a cloud partner for this project.

    Next Steps
    follow up meeting next week to deep dive into the requirements for the webhosting, type of data for the website, dynamic etc. with the webshop owner, Fabiana Arco involved. 

  `
  const [translateContent, setTranslateContent] = useState(`Output must keep the source writen language. Do not translate output to a different language than input call summary.`);
  const [checked, setChecked] = useState(false);
  const [appConst, setAppConst] = useState({  // Use unified dictionary to manage all useState
    "summaryContent": "",
    "inValue": "",
    "outValue": "",
    "activeStepIndex": 0,
    "isLoadingNextStep": false,
    "hideLoader": true,
    "progressVal": 0,
    "statusInfo": "",
    "progressStatus": "in-progress",
    "progressResultText": "Success",
    "summaryRatingValue": 0,
    "bantcpRatingValue": 0,
    "marketInsightsRatingValue": 0,
    "outOppsNum": 0,
    "outOppsContent": "",
    "marketInsights": "",
    "submitedFeedback": false
  })
  // Init midway session token
  const [accessToken, setAccessToken] = useState({
    jwtToken: null,
    given_name: null,
    family_name: null,
    email: null,
    exp: null,
    iat: null,
    auth_time: null,
  });
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  ///// Handle midway Auth //////

  // Gather currentSession info
  function getToken() {
    //return fetchAuthSession() // V6_aws-amplify
    return Auth.currentSession() // V5_aws-amplify
    .then(session => session)
      .catch(err => console.log(err))
  }

  // Trigger midway Federated identity auth
  async function federateIdentity() {
    try {
      //await signInWithRedirect({ provider: federatedIdName }); // V6_aws-amplify
      await Auth.federatedSignIn({ provider: federatedIdName }); // V5_aws-amplify
      setIsAuthenticating(false);
    } catch (error) {
      console.error('Error during federated sign-in:', error);
      setIsAuthenticating(true);
    }
    return;
  }

  // Listen for aws-amplify auth events and set session data on 'signIn'
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          try {
            setAccessToken({
              jwtToken: data.signInUserSession.idToken.jwtToken,
              given_name: data.signInUserSession.idToken.payload.given_name,
              family_name: data.signInUserSession.idToken.payload.family_name,
              email: data.signInUserSession.idToken.payload.email,
              exp: data.signInUserSession.idToken.payload.exp,
              iat: data.signInUserSession.idToken.payload.iat,
              auth_time: data.signInUserSession.idToken.payload.auth_time,
            });
            if (setDebug) {
              console.log('signIn: ', data);
              console.log('signIn_accessToken: ', accessToken);
            }
            setIsAuthenticating(false);
          } catch (error) {
            console.error('Error handling auth response:', error);
            setIsAuthenticating(false);
          }
          break;
        case 'cognitoHostedUI':
          if (setDebug) {
            console.log('cognitoHostedUI: ', data);
            console.log('cognitoHostedUI_accessToken: ', accessToken);
          }
          break;
        case 'signIn_failure':
          console.log('signIn_failure: ', data);
          break;
        case 'cognitoHostedUI_failure':
          console.log('cognitoHostedUI_failure: ', data);
          setIsAuthenticating(false);
          break;
        default:
          break;
      }
    });


    // Only when loading page: check if exists current token and not expired OR authenticate user
    if (isAuthenticating) {
      getToken().then(userToken => {
        let currtime = Math.floor(Date.now() / 1000)
        if(userToken && (userToken.idToken.payload.exp > currtime)){
          setAccessToken({
            jwtToken: userToken.idToken.jwtToken,
            given_name: userToken.idToken.payload.given_name,
            family_name: userToken.idToken.payload.family_name,
            alias: userToken.idToken.payload.email.split("@")[0],
            email: userToken.idToken.payload.email,
            exp: userToken.idToken.payload.exp,
            iat: userToken.idToken.payload.iat,
            auth_time: userToken.idToken.payload.auth_time,
          });
          setIsAuthenticating(false);
        } else {
          federateIdentity();
        }
      })
    }

  }, []);
  
  // Handle copy to clipboard
  function copyToClipboard (val) {
    navigator.clipboard.writeText(val)
  }

  // Show input changes and update inValue for later ussage
  function handleInputChange(detail) {
    setAppConst(previousState => {
      return { ...previousState, "inValue": detail.value}})
  }

  // Cleanup JSON output from Bedrock
  function cleanUpOuputJSON(outputBedrock) {
    const searchChar = '{'
    try {
      const firstBraceIndex = outputBedrock.indexOf(searchChar);
      const lastBraceIndex = outputBedrock.lastIndexOf('}');

      if (firstBraceIndex !== -1 && lastBraceIndex !== -1) {
        return outputBedrock.slice(firstBraceIndex, lastBraceIndex + 1);
      } else {
        return outputBedrock;
      }
    } catch (error) {
      return outputBedrock;
    }
  }

  // Handle how output is being shown in all page components
  function showResults(bantcp_message){
    //
    // Update progress bar
    //
    setAppConst(previousState => {
      return { ...previousState, 
      "progressVal": 80,
      "statusInfo": "Cleaning output.."
    }})

    // set current date
    const date = new Date();
    let day = ("0" + date.getDate()).slice(-2);
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
    let year = `${date.getFullYear()}`.slice(2);

    if(setDebug) {
      console.log(bantcp_message);
    }
    
    setAppConst(previousState => {
      return { ...previousState, 
      "summaryContent": bantcp_message.summary,
      "outOppsNum": bantcp_message.opps_number,
      "marketInsights": bantcp_message.market_insight,
      "outValue": bantcp_message,
      "outOppsContent": bantcp_message.opps.map((opp, index) => {
        const next_steps = `${month}/${day}/${year} - [DGR/CSR/SA @login], ${opp.next_steps.What}, ${opp.next_steps.Whom}, by ${opp.next_steps.When}, ${opp.next_steps.Why}`
        return(
          <ExpandableSection key={index}
            headingTagOverride="h4"
            variant="container"
            headerActions={
              <Popover
                dismissButton={false}
                position="top"
                size="small"
                triggerType="custom"
                content={
                  <StatusIndicator type="success">
                    Opportunity copied!
                  </StatusIndicator>
                }
              >
                <Button
                  variant="inline-link"
                  iconName="copy"
                  onClick={() => {
                    copyToClipboard(JSON.stringify(opp, null, 2))
                  }}
                >
                  Copy
                </Button>
              </Popover>
            }
            headerText={`${index+1}- ${opp.opportunity_name}`}
            >
            <SpaceBetween size="m">
              <FormField
                stretch
                label="Budget"
                info={
                  <Button
                    variant="inline-icon"
                    iconName="copy"
                    onClick={() => {
                      copyToClipboard(opp.bantcp.B);
                    }}
                  >
                    copy
                  </Button>
                }
              >
                <Textarea
                  rows={1} 
                  value={opp.bantcp.B}
                  readOnly
                />
              </FormField>
              <FormField
                stretch
                label="Authority"
                info={
                  <Button
                    variant="inline-icon"
                    iconName="copy"
                    onClick={() => {
                      copyToClipboard(opp.bantcp.A);
                    }}
                  >
                    copy
                  </Button>
                }
              >
                <Textarea
                  rows={1} 
                  value={opp.bantcp.A}
                  readOnly
                />
              </FormField>
              <FormField
                stretch
                label="Need"
                info={
                  <Button
                    variant="inline-icon"
                    iconName="copy"
                    onClick={() => {
                      copyToClipboard(opp.bantcp.N);
                    }}
                  >
                    copy
                  </Button>
                }
              >
                <Textarea
                  rows={2} 
                  value={opp.bantcp.N}
                  readOnly
                />
              </FormField>
              <FormField
                stretch
                label="Timeline"
                info={
                  <Button
                    variant="inline-icon"
                    iconName="copy"
                    onClick={() => {
                      copyToClipboard(opp.bantcp.T);
                    }}
                  >
                    copy
                  </Button>
                }
              >
                <Textarea
                  rows={1} 
                  value={opp.bantcp.T}
                  readOnly
                />
              </FormField>
              <FormField
                stretch
                label="Competition"
                info={
                  <Button
                    variant="inline-icon"
                    iconName="copy"
                    onClick={() => {
                      copyToClipboard(opp.bantcp.C);
                    }}
                  >
                    copy
                  </Button>
                }
              >
                <Textarea
                  rows={1} 
                  value={opp.bantcp.C}
                  readOnly
                />
              </FormField>
              <FormField
                stretch
                label="Partner"
                info={
                  <Button
                    variant="inline-icon"
                    iconName="copy"
                    onClick={() => {
                      copyToClipboard(opp.bantcp.P);
                    }}
                  >
                    copy
                  </Button>
                }
              >
                <Textarea
                  rows={1} 
                  value={opp.bantcp.P}
                  readOnly
                />
              </FormField>
              
              <hr/>
              <FormField
                stretch
                label="Next steps"
                info={
                  <Button
                    variant="inline-icon"
                    iconName="copy"
                    onClick={() => {
                      copyToClipboard(next_steps);
                    }}
                  >
                    copy
                  </Button>
                }
              >
                <Textarea
                  rows={2} 
                  value={next_steps}
                  readOnly
                />
              </FormField>
            </SpaceBetween> 
          </ExpandableSection>
        )
      })
    }})
    
    // Update Wizard view to show output
    i18nStrings.nextButton="Next"
    setAppConst(previousState => {
      return { ...previousState, 
      "isLoadingNextStep": false,
      "activeStepIndex": appConst.activeStepIndex+1,
      "progressVal": 100,
      "progressStatus": "success",
      "statusInfo": ""
    }})
  }

  // Function covers sending prompt for second stage (getting results)
  async function secondStagePrompt(req_prompt) {
    // Update progress bar
    setAppConst(previousState => {
      return { ...previousState, 
      "progressVal": 40,
      "statusInfo": "Processing BANTCP & summary.."
    }})

    try {
      const apiName = 'api284d7fa0';
      const path = '/predict';
      const data = {
        body: {
          prompt: setPromptType("bantcp"),
          data: req_prompt
        },
        headers: {
          'authorizationToken': JSON.stringify(process.env.REACT_APP_LAMBDA_AUTH_TOKEN)
        }
      };
    
      const restOP = await API.post(apiName, path, data); // V5_aws-amplify 
      //const restOP = await post({apiName, path, options: data}); // V6_aws-amplify 
    
      if (setDebug) {
        console.log("API calls finished:", restOP);
      }
    
      const response = await restOP;
      showResults(cleanUpOuputJSON(response));
    } catch (error) {
      console.error("Error with second prompt API call:", error);
      i18nStrings.nextButton = "Next";
      setAppConst(previousState => {
        return {
          ...previousState,
          "isLoadingNextStep": false,
          "progressVal": 0,
          "progressStatus": "error",
          "progressResultText": "Oops! Something went wrong, please reset wizard and try again",
          "statusInfo": ""
        }
      });
    }

  }

  // Set prompt context for each type
  //  More scenarios can be covered by just adding prompt_context to this function and being requested through secondStagePrompt
  function setPromptType (prompt_type) {
    let prompt_context = ""

    switch (prompt_type) {
      case "summarize":
        prompt_context = `"
        The following text are meeting notes between a seller of the cloud provider AWS and a potential customer. 
        Output reply must only contain a JSON keeping this structure {"summary": "input text summary. Do not leave out any important or quantitative information. "}
        Give me a detailed summary of the following call notes:
        "`
        break
      case "bantcp":
        prompt_context = `"
          <instructions>
          You are a sales person at Amazon Web Services (AWS) qualifying sales opportunities from a customer conversation that has been recorded in a written document of notes. 

          From these notes, you are now to find out the following variables to qualify one or multiple opportunities: 

          "Opportunity": the title of the proposed cloud workload involved in the customer project summarised in a few words. For example, a company that is looking to migrate their Microsoft workloads to the cloud may have an opportunity of "Microsoft Workload Migration". A company that is looking to optimise their costs on AWS will have an opportunity name of "Cost Optimisation" and so on. 

          "Budget": the estimated monthly spend once the opportunity has been launched. A launch means that the customer is spending on AWS. The budget should equal what the customer is expected to spend monthly after launch. If no concrete monthly spend is available, output the quantified data from the meeting notes that will help with a manual estimation of the cost. For example, a company may have 120 servers to migrate onto the cloud, but there is no mention of a dollar amount. In this case, mention that "120 server count can be used for budget estimation". Otherwise output the budget as "budget MRR" 

          "Authority": the customer point of contact that the salesperson spoke to within the conversation. Provide the name and position of this point of contact when outputting the authority. For example "IT manager, Mark Gustavson" 

          "Need": The specific customer requirements and needs including all the opportunity details. 

          "Timeline": The time in which the opportunity is expected to launch and the customer will start spending the estimated budget. 

          "Competition": alternative solutions that the customer is considering to use. Including but not limited to companies such as Microsoft, Google, GCP, IBM, Azure, or on premise infrastructure. Competition does not mean a competitive company to that of the customer, but rather an alternative solution to AWS. 

          "Partner": an external party that will support the project. This can be a consulting partner or technology partner (a software solution). Please mention them by name if this information is available. If not, provide a one sentence summary of partner information available. For example, if in the notes it is evident that the customer is not interested in a partner because they have sufficient cloud knowledge you can output "not interested in partner due to sufficient internal cloud knowledge" 

          “NextStepsWhat”: The next specific action for the opportunity after the call 

          “NextStepsWhom": Who is involved (name and position in company) from the customer side. This will likely be the same output as "authority". 

          “NextStepsWhen": Exact, 6-digit date in the format MM/DD/YY 

          “NextStepsWhy”: Why is this the next step What is the “Project Title”, "Budget", "Authority", "Need", "Timeline", "Competition", "Partner" and "Next Steps" of the following call notes? 

          "MarketInsight": A market insight is any interesting information about the customer, about the market, their challenges, or sentiment about the cloud. Please output the format of the market insight in the following format: more than 40 words, start each output with "#sensor", include the words "discuss" and/or "share", and "should" or "need" or "ask" or "why" or "recommend". Please only output one market insight.

          Do not invent anything, if you do not know the answer to one of them put it as N/A. 

          Translate input given notes into english to get a better output processing.

          If you identify more than one opportunity, return the output for each opportunity identified. Please also identify market insights. 

          ${translateContent}

          </instructions>

          <example>
          Output reply must only contain a JSON keeping this structure { 
              "opps_number": number of identified opportunities, 
              "summary": The following text are meeting notes between a seller of the cloud provider AWS and a potential customer. Output reply must input text summary. Show at least 100 words in summary. Do not leave out any important or quantitative information. Give me a detailed summary of input call notes. Must provide a single-line summary. , 
              "market_insight": MarketInsight,
              "opps": [
              { 
                  "opportunity_name": Opportunity, 
                  "bantcp": { 
                      B: Budget, 
                      A: Authority, 
                      N: Need, 
                      T: Timeline, 
                      C: Competition, 
                      P: Partner 
                  }, 
                  "next_steps": { 
                      What: NextStepsWhat, 
                      Whom: NextStepsWhom, 
                      When: NextStepsWhen, 
                      Why: NextStepsWhy 
                  }
              }
          ]} 
          <example>

          The following is the call summary in one or multiple chunks: 
        "`
        break
      default:
        prompt_context = ''
        break
    }
    
    if (setDebug) {
      console.log(`Prompt_type: ${prompt_type}`)
    }

    return prompt_context
  }

  // Submit prompt
  async function submitPrompt(val) {
    // Update progress bar
    setAppConst(previousState => {
      return { ...previousState, 
      "progressVal": 20,
      "statusInfo": "Processing response input.."
    }})

    // 1. Split input into small chunks lower than max_len (due to conext length)
    let chunks_list = val.match(new RegExp(".{1," + max_len + "}", "gs"))

    if(setDebug) {
      console.log("Input split into chunks: ", chunks_list)
    }

    // 2. Request BANTCP & Summarization for each chunk to LLM
    // updated to only 1 prompt request with all requirements on it
    secondStagePrompt(val)

    // 2. Request summarization for each chunk to LLM
    /*try {
      const restOP = API.post({
        apiName: 'api284d7fa0',
        path: '/predict',
        options: {
          body: {
            prompt: setPromptType("summarize"),
            data: chunks_list
          },
          headers: {
            'authorizationToken': JSON.stringify(process.env.REACT_APP_LAMBDA_AUTH_TOKEN)  
          }
        }
      })
      await restOP.response
  
      // process responses
      if(setDebug) {
        console.log("API calls finished:", restOP.response)
      }
      (await restOP.response).body.json().then(data => ({
          data: data,
          status: restOP.response.status
        }, 
        secondStagePrompt(val, data)
      ))
    } catch (error) {
      // handle error
      console.error("Error with summary API call:", error)
      // Change wizard status
      i18nStrings.nextButton="Next"
      setAppConst(previousState => {
        return { ...previousState, 
        "isLoadingNextStep": false,
        "progressVal": 0,
        "progressStatus": "error",
        "progressResultText": "Oops! Something went wrong, please reset wizard and try again",
        "statusInfo": ""
      }})
    }*/
  }

  // Handle submit
  function handleSubmit() {
    // Move only forward with new info
    if (appConst.inValue.length > 0) {
      // 0. Set submit button lading
      setAppConst(previousState => {
        return { ...previousState, 
        "isLoadingNextStep": true,
        "hideLoader": false
      }})
      submitPrompt(appConst.inValue)
    } else {
      alert('Please paste meeting notes')
    }
  }

  // Handle Wizard reset
  function handleReset() {
    // Window reload
    window.location.reload() 
  }

  // Handle Feedback
  async function handleFeedback() {
    // Submit feedback to DynamoDB through Lambda
    try {
      const apiName = 'api284d7fa0';
      const path = '/feedback';
      const data = {
        body: {
          id: Date.now(),
          summary: appConst.summaryRatingValue,
          bantcp: appConst.bantcpRatingValue,
          insights: appConst.marketInsightsRatingValue,
        },
        headers: {
          'authorizationToken': JSON.stringify(process.env.REACT_APP_LAMBDA_AUTH_TOKEN)
        }
      };
    
      const restOP = await API.post(apiName, path, data); // V5_aws-amplify 
      //const restOP = await post({apiName, path, options: data}); // V6_aws-amplify 
    
      if (setDebug) {
        console.log("API calls finished:", restOP);
      }
    } catch (error) {
      // Handle error
    }
  }

  return isAuthenticating ? (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Spinner size="large" />
        <p>Authenticating...</p>
      </div>
    ) : accessToken.jwtToken ? (
      // 0. Main Content Layout
      <ContentLayout
        header={
        <TopNavigation
          identity={{
            title: `${TOOLNAME}`,
            href: "/",
            logo: { src: logo, alt: "AWS" }
          }}
          utilities={[
          {
            type: "menu-dropdown",
            iconName: "",
            text: "Reference links",
            items: [
              {
                id: "oppaid-wiki",
                text: "OppAId Project Wiki",
                href: "https://w.amazon.com/bin/view/AWS/EMEA/SA/CSC/GenAI_/OppAId/",
                external: true,
              },
              {
                id: "emea-dg-ho-sop",
                text: "EMEA DG Opportunity SOP",
                href: "https://w.amazon.com/bin/view/EMEADGRKnowledgeCentre/DGSOP/",
                external: true,
              }
            ]
          },
          {
            type: "button",
            text: accessToken.alias,
            //description: accessToken.email,
            iconName: "user-profile",
            items: [
              /*{
                id: "signout",
                text: "Sign Out",
                href: "https://midway-auth.amazon.com/logout",
                external: false,
              }*/
            ]
          }
          ]}
          i18nStrings={i18nStrings}
        />
      } >
        <AppLayout
          contentType="wizard"
          toolsHide
          navigationHide
          content={
            <ContentLayout>
              <Wizard
                onCancel={() => handleReset()}
                onSubmit={() => handleReset()}
                i18nStrings={i18nStrings}
                isLoadingNextStep={appConst.isLoadingNextStep}
                onNavigate={({ detail }) => {
                  if (appConst.activeStepIndex === 2 && detail.requestedStepIndex>appConst.activeStepIndex && appConst.progressStatus==="in-progress") handleSubmit()
                  else { 
                    if (appConst.activeStepIndex === 1 && detail.requestedStepIndex>appConst.activeStepIndex && appConst.progressStatus==="in-progress") {
                      if (appConst.inValue.length < max_len) {i18nStrings.nextButton="Submit"; i18nStrings.cancelButton="Reset"}
                      else {
                        alert(`Input text is too long. Remember that max length is ${max_len} characters.`)
                        detail.requestedStepIndex = appConst.activeStepIndex
                      }
                    }
                    else if (appConst.activeStepIndex === 2 && detail.requestedStepIndex>appConst.activeStepIndex) i18nStrings.cancelButton=""
                    else {i18nStrings.nextButton="Next"; i18nStrings.cancelButton="Reset"; } 
                    setAppConst(previousState => {
                      return { ...previousState, "activeStepIndex": detail.requestedStepIndex}})}
                }}
                activeStepIndex={appConst.activeStepIndex}
                steps={[
                  {
                    title: "Overview",
                    description:
                        "",
                    content: (
                      <SpaceBetween size="l" >
                        <Container>
                          <TextContent>
                            <h3> Welcome to <b>{TOOLNAME}</b>! </h3>
                            <p> {TOOLNAME} was designed to efficiently extract relevant information (such as summary, BANTCP, Market Insights) from customer meeting notes. </p>
                          </TextContent>
                        </Container>
                        
                        {/*<Alert                                                                       //Show initial Warning to remark PII message
                            statusIconAriaLabel="warning"
                            type="warning"
                            header="IMPORTANT:"
                          >
                            <p> Do not use Customer PII or sensitive AWS information when pasting meeting notes into the input text box. </p>
                            <p> Make sure to review your notes before submission. </p>
                        </Alert>*/}
                        <Alert                                                                                  //Show initial Warning to remark PII message
                            statusIconAriaLabel="info"
                            type="info"
                            header="DISCLAIMER:"
                          >
                            <p> The quality of the output depends on numerous factors, including the level of detail of your notes. 
                              It is possible that the tool misses a qualified opportunity, that it returns more qualified opportunities 
                              than available or that it does not return specific information related to the opportunities that is available in the call notes. </p>
                            <p> Make sure to do a cross-check yourself after using the tool. </p>
                        </Alert>  
                      </SpaceBetween>
                    )
                  },
                  {
                    title: "Input",
                    description: 
                      "Paste your meeting notes. Output language will be consistent to your inputs, unless you enable output translation to English.",
                    content: (
                      <Container> <SpaceBetween size="xl">

                        <ColumnLayout columns={12} >
                          <Button iconName="envelope" variant="primary" ariaLabel="Clear" onClick={e => {setAppConst(previousState => {
                                    return { ...previousState, "inValue":sampleNotes}})}}>
                            Paste sample notes
                          </Button>
                          <Button variant="normal" ariaLabel="Clear" onClick={e => {setAppConst(previousState => {
                                    return { ...previousState, "inValue":""}})}}>
                            Clear contents
                          </Button>
                        </ColumnLayout>


                        <Textarea
                          autoFocus
                          onChange={e => handleInputChange(e.detail)}
                          value={appConst.inValue}
                          ariaRequired
                          placeholder="Paste input information"
                          rows={textRows}
                        />
                        <Toggle
                          onChange={({ detail }) => {
                            if(detail.checked) {
                              setTranslateContent(`Output must be writen in English language.`);
                            } else {
                              setTranslateContent(`Output must keep the source writen language. Do not translate output to a different language than input call summary.`);
                            }
                            setChecked(detail.checked);
                          }}
                            checked={checked}
                          >
                            Translate output to English
                        </Toggle>
                      
                      </SpaceBetween> 
                      </Container>
                    )
                  },
                  {
                    title: "Review & Submit",
                    description:
                        "Check your meeting notes before submitting them.",
                    content: 
                      /* Control progress bar visibility  */
                      (appConst.hideLoader && 
                        <Container
                          header={
                            <Header
                              variant="h2"
                            >
                            Confirm submission
                          </Header>
                        } >
                          <p>Are you sure you want to submit this text? {/*<br/> <br/> Please ensure it does not contain any customer PII or sensitive AWS information.*/}</p>
                        </Container>
                      ) ||
                      (!appConst.hideLoader && 
                        (appConst.progressStatus=='success' &&    
                          <Container> 
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <StatusIndicator />
                              <span style={{ marginLeft: '0.5rem' }}>
                                Check your output results or Reset for a new opp
                              </span>
                            </div>
                          </Container>
                        ) || 
                        (appConst.progressStatus=='in-progress' &&
                          <Container> 
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Spinner size="big" variant="disabled" />
                              <span style={{ marginLeft: '0.5rem' }}>
                                Please wait for {TOOLNAME} to process the notes, this may take a few seconds.
                              </span>
                            </div>
                          </Container>
                        ) || 
                        (appConst.progressStatus=='error' &&    
                          <Container> 
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <StatusIndicator type="error" />
                              <span style={{ marginLeft: '0.5rem' }}>
                                {appConst.progressResultText}
                              </span>
                            </div>
                          </Container>
                        )

                        /*<br/> <br/> <br/>
                        <ProgressBar
                          value={appConst.progressVal}
                          status={appConst.progressStatus}
                          resultText={appConst.progressResultText}
                          variant="key-value"
                          additionalInfo={appConst.statusInfo}
                          label={`Please wait for the ${TOOLNAME} tool to load, this may take a few seconds`}
                        />
                        <br/> <br/> <br/>*/
                      )
                  },
                  {
                    title: "Output",
                    description:
                        "Check your results before using them.",
                    content: (
                      <SpaceBetween size="m" >  
                        <Alert                                                                       //Show initial Warning to remark PII message
                            statusIconAriaLabel="info"
                            type="info"
                            header="REMINDER:"
                          >
                            <p> <b>{TOOLNAME} tool</b> is in early stage and the quality of the output depends on your input. Please review that the output matches the right input information. </p>
                        </Alert>
                        {/* Display Input */}
                        <ExpandableSection
                          headingTagOverride="h3"
                          variant="container"
                          headerText="Input"
                        >
                          
                          <Textarea
                            value={appConst.inValue}
                            placeholder="Input text will be displayed here"
                            readOnly
                            rows={10}
                          />
                          
                        </ExpandableSection>

                        {/* Display summary - Both summary and Output content is managed by showResults() */}
                        <ExpandableSection
                          defaultExpanded
                          headingTagOverride="h3"
                          variant="container"
                          headerText="Summary"
                          headerActions={
                            <Popover
                              dismissButton={false}
                              position="top"
                              size="small"
                              triggerType="custom"
                              content={
                                <StatusIndicator type="success">
                                  Summary copied!
                                </StatusIndicator>
                              }
                            >
                              <Button
                                variant="inline-link"
                                iconName="copy"
                                onClick={() => {
                                  copyToClipboard(appConst.summaryContent)
                                }}
                              >
                                Copy
                              </Button>
                            </Popover>
                          }
                          >
                          <TextContent>
                            {appConst.summaryContent}
                          </TextContent>  
                        </ExpandableSection>

                        {/* Display market insights - Managed by showResults() */}
                        <ExpandableSection
                          defaultExpanded
                          headingTagOverride="h3"
                          variant="container"
                          headerText="Market Insights"
                          headerActions={
                            <Popover
                              dismissButton={false}
                              position="top"
                              size="small"
                              triggerType="custom"
                              content={
                                <StatusIndicator type="success">
                                  Market Insights copied!
                                </StatusIndicator>
                              }
                            >
                              <Button
                                variant="inline-link"
                                iconName="copy"
                                onClick={() => {
                                  copyToClipboard(appConst.marketInsights)
                                }}
                              >
                                Copy
                              </Button>
                            </Popover>
                          }
                          >
                          <TextContent>
                            {appConst.marketInsights}
                          </TextContent>  
                        </ExpandableSection>
              
                        {/* Display output */}
                        <ExpandableSection
                          defaultExpanded
                          headingTagOverride="h3"
                          variant="container"
                          headerText="Output"
                          headerDescription={`Number of identified opportunities: ${appConst.outOppsNum}`}
                          > 
                          <SpaceBetween size="m">
                            {appConst.outOppsContent}
                          </SpaceBetween>
                        </ExpandableSection>

                        <Popover
                          dismissButton={false}
                          position="top"
                          size="small"
                          triggerType="custom"
                          content={
                            <StatusIndicator type="success">
                              Outputs copied!
                            </StatusIndicator>
                          }
                        >
                          <Button variant="normal" onClick={() => {copyToClipboard(JSON.stringify(appConst.outValue))}}>
                            Copy all outputs
                          </Button>
                        </Popover>
                      </SpaceBetween>
                    )  
                  },
                  {
                    title: "Feedback",
                    description:
                        "Help us to improve the tool.",
                    content: (
                      <Form variant="embedded">
                        <SpaceBetween size="s">
                          <TextContent>
                            <h3> Help us improve the {TOOLNAME} tool by providing your feedback! </h3>
                            <p> How helpful was the tool for generating a summary of your customers meeting notes? </p>
                          </TextContent>
                          <ReactStars
                            count={5}
                            size={25}
                            color1={'#cccccc'}
                            color2={'#ffbc09'} 
                            value={appConst.summaryRatingValue}
                            onChange={rate  => {setAppConst(previousState => {
                              return { ...previousState, "summaryRatingValue": rate}})}}
                          />
                          
                          <TextContent>
                            <p> How helpful was the tool for generating your BANTCP information? </p>
                          </TextContent>
                          <ReactStars
                            count={5}
                            size={25}
                            color1={'#cccccc'}
                            color2={'#ffbc09'} 
                            value={appConst.bantcpRatingValue}
                            onChange={rate  => {setAppConst(previousState => {
                              return { ...previousState, "bantcpRatingValue": rate}})}}
                          />

                          <TextContent>
                            <p> How helpful was the tool for generating Market Insights? </p>
                          </TextContent>
                          <ReactStars
                            count={5}
                            size={25}
                            color1={'#cccccc'}
                            color2={'#ffbc09'} 
                            value={appConst.marketInsightsRatingValue}
                            onChange={rate  => {setAppConst(previousState => {
                              return { ...previousState, "marketInsightsRatingValue": rate}})}}
                          />

                          <Alert                                                                                  //Show initial Warning to remark PII message
                            statusIconAriaLabel="info"
                            type="info"
                            header=""
                          >
                            <span> Remember that you can find the {TOOLNAME} team in <a href="https://w.amazon.com/bin/view/AWS/EMEA/SA/CSC/GenAI_/OppAId/">this Wiki</a> to share your thoughts with the tool’s developers.</span>
                          </Alert>
                          <Popover
                            dismissButton={false}
                            position="top"
                            size="small"
                            triggerType="custom"
                            content={
                              <StatusIndicator type="success">
                                Feedback submitted! 
                              </StatusIndicator>
                            }>
                            <Button
                              disabled = {appConst.submitedFeedback}
                              variant="normal"
                              onClick={() => handleFeedback()}
                            >
                              Submit feedback
                            </Button>
                          </Popover>
                        </SpaceBetween>
                      </Form>
                    )
                  }]
                } 
              />
            </ContentLayout>
          }
        >
        </AppLayout>
      </ContentLayout>
    ) : null;
}

export default App;
